import { Box } from '@mui/material';

import { Maybe } from '@/types';

import ImageAsset, { IImage } from '../ImageAsset/ImageAsset';

import { IPatientReviews } from './PatientReviews';
import { imageBoxStyles, sideImageStyles } from './PatientReviews.styles';

export const SideImage = ({
	imageAsset,
	hasButtonContent,
}: {
	imageAsset?: IPatientReviews['imageLeftSide'];
	hasButtonContent?: Maybe<boolean>;
}) => {
	const widthSize = '100%';
	const objectFit = 'contain';
	return (
		<Box data-test-id="patient_reviews_sideimage-container" sx={imageBoxStyles}>
			<Box sx={sideImageStyles(hasButtonContent)}>
				<ImageAsset
					desktopImage={imageAsset?.desktopImage as IImage}
					mobileImage={imageAsset?.mobileImage as IImage}
					isBackgroundImage={false}
					imageSx={{ objectFit: objectFit, width: widthSize }}
					dataTestId={`patient-reviews_sideimage-left`}
				/>
			</Box>
		</Box>
	);
};
