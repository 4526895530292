import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';

import {
	ImageAsset as TImageAsset,
	PatientReviewsV2,
	PatientReviewsV2ButtonsLargeScreensCollection,
	PatientReviewsV2ButtonsSmallScreensCollection,
	PatientReviewsV2ReviewsCollection,
} from '@/types/generated';
import {
	ContentfulBackground,
	ContentfulLightBackground,
	HeadingTag,
	Maybe,
	TReviewsMode,
	TemplateWidth,
} from '@/types';
import { resolveContentfulBgColor } from '@/utils/resolveContentfulBgColor';
import resolveContentfulHeadingTag from '@/utils/resolveContentfulHeadingTag';

import TemplateContainer from '../TemplateContainer';
import ContentfulButton from '../ContentfulButton';
import { IconName } from '../TAGSvgIcon';

import { buttonContainerStyles, getContainerStyles, getTitleContainerStyles } from './PatientReviews.styles';
import Reviews from './Reviews';
import { resolveContentAlignment } from './PatientReviews.helpers';
import { getSwiperContainerStyles } from './Reviews/ReviewsCarousel/ReviewCarousel.styles';
import { SideImage } from './SideImage';

export interface IPatientReviews extends PatientReviewsV2 {
	/**
	 * Background color of the section.
	 *
	 * @type {Maybe<ContentfulLightBackground>}
	 * @memberof IPatientReviews
	 */
	backgroundColor?: Maybe<ContentfulLightBackground>;
	/**
	 * Width of the section. Can be Full or Inset.
	 *
	 * @type {Maybe<TemplateWidth>}
	 * @memberof IPatientReviews
	 * @default 'Full'
	 */
	templateWidth?: Maybe<TemplateWidth>;
	/**
	 * Small text shown above the title on small screens
	 *
	 * @type {Maybe<string>}
	 * @memberof IPatientReviews
	 */
	eyebrowSmallScreens?: Maybe<string>;
	/**
	 * Small text shown above the title on small screens
	 *
	 * @type {Maybe<string>}
	 * @memberof IPatientReviews
	 */
	eyebrowLargeScreens?: Maybe<string>;
	/**
	 * Large text shown as the headline on small screens
	 *
	 * @type {Maybe<string>}
	 * @memberof IPatientReviews
	 */
	titleSmallScreens?: Maybe<string>;
	/**
	 * Large text shown as the headline on large screens
	 *
	 * @type {Maybe<string>}
	 * @memberof IPatientReviews
	 */
	titleLargeScreens?: Maybe<string>;
	/**
	 * Large text shown as the headline on large screens
	 *
	 * @type {Maybe<HeadingTag>}
	 * @memberof IPatientReviews
	 * @default 'h1'
	 */
	titleHtag?: Maybe<HeadingTag>;
	/**
	 * Text below the title on small screens
	 *
	 * @type {Maybe<string>}
	 * @memberof IPatientReviews
	 */
	subtitleSmallScreens?: Maybe<string>;
	/**
	 * Text below the title on large screens
	 *
	 * @type {Maybe<string>}
	 * @memberof IPatientReviews
	 */
	subtitleLargeScreens?: Maybe<string>;
	/**
	 * Patient reviews
	 *
	 * @type {Maybe<PatientReviewsV2ReviewsCollection>}
	 * @memberof IPatientReviews
	 */
	reviewsCollection?: Maybe<PatientReviewsV2ReviewsCollection>;
	/**
	 * Number of reviews to display on small screens
	 *
	 * @type {Maybe<number>}
	 * @memberof IPatientReviews
	 * @default 2
	 */
	maxReviewsSmallScreens?: Maybe<number>;
	/**
	 * Number of reviews to display on large screens
	 *
	 * @type {Maybe<number>}
	 * @memberof IPatientReviews
	 * @default 4
	 */
	maxReviewsLargeScreens?: Maybe<number>;
	/**
	 * Number of characters in a review's text before it's truncated and the "More" link is displayed
	 *
	 * @type {Maybe<number>}
	 * @memberof IPatientReviews
	 * @default 160
	 */
	trimThreshold?: Maybe<number>;
	/**
	 * Action buttons below the reviews to display on small screens
	 *
	 * @type {Maybe<PatientReviewsV2ButtonsSmallScreensCollection>}
	 * @memberof IPatientReviews
	 */
	buttonsSmallScreensCollection?: Maybe<PatientReviewsV2ButtonsSmallScreensCollection>;
	/**
	 * Action buttons below the reviews to display on large screens
	 *
	 * @type {Maybe<PatientReviewsV2ButtonsLargeScreensCollection>}
	 * @memberof IPatientReviews
	 */
	buttonsLargeScreensCollection?: Maybe<PatientReviewsV2ButtonsLargeScreensCollection>;
	/**
  * Specifies the content alignment. Can be Left, Center or Right. Defaults to Center.
  *
  * @type {Maybe<string>}
  * @memberof IPatientReviews
    @default 'Center'
  */
	contentAlign?: Maybe<string>;
	/**
	 * Review list view mode
	 *
	 * @type {Maybe<TReviewsMode>}
	 * @memberof IPatientReviews
	 */
	mode?: TReviewsMode;
	/**
	 * Score icon name
	 *
	 * @type {Maybe<IconName>}
	 * @memberof IPatientReviews
	 */
	scoreIcon?: IconName;
	/**
	 * Left Side Image for patient review section
	 *
	 * @type {Maybe<ImageAsset>}
	 * @memberof IPatientReviews
	 */
	imageLeftSide?: Maybe<TImageAsset>;
}

export const defaults = {
	maxReviewsSmallScreens: 2,
	maxReviewsLargeScreens: 4,
	templateWidth: 'Full' as TemplateWidth,
	trimThreshold: 160,
};

export default function PatientReviews({
	backgroundColor,
	buttonsLargeScreensCollection,
	buttonsSmallScreensCollection,
	eyebrowLargeScreens,
	eyebrowSmallScreens,
	maxReviewsLargeScreens,
	maxReviewsSmallScreens,
	reviewsCollection,
	subtitleLargeScreens,
	subtitleSmallScreens,
	templateWidth,
	titleHtag = 'h1',
	titleLargeScreens,
	titleSmallScreens,
	trimThreshold,
	contentAlign = 'Center',
	mode = 'list',
	scoreIcon,
	imageLeftSide,
}: Readonly<IPatientReviews>) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

	const eyebrow = isSmallScreen ? eyebrowSmallScreens : eyebrowLargeScreens;
	const title = isSmallScreen ? titleSmallScreens : titleLargeScreens;
	const subtitle = isSmallScreen ? subtitleSmallScreens : subtitleLargeScreens;
	const hasAnyTitle = eyebrow || title || subtitle;

	const maxReviews = isSmallScreen
		? maxReviewsSmallScreens || defaults.maxReviewsSmallScreens
		: maxReviewsLargeScreens || defaults.maxReviewsLargeScreens;
	const reviews = reviewsCollection?.items?.slice(0, maxReviews) || [];

	const buttons = (isSmallScreen ? buttonsSmallScreensCollection?.items : buttonsLargeScreensCollection?.items) || [];
	const hasButtonContent = buttons.length > 0;
	const isDarkBackground = !!backgroundColor?.toLowerCase().includes('dark');

	const bgColor = resolveContentfulBgColor(backgroundColor as ContentfulBackground, theme);

	return (
		<TemplateContainer
			templateWidth={templateWidth || 'Full'}
			containerSx={
				mode === 'carousel'
					? getSwiperContainerStyles(isDarkBackground)
					: getContainerStyles(contentAlign, hasButtonContent, !!imageLeftSide)
			}
			dataTestId="section_patient_reviews"
			contentfulBackgroundColor={bgColor}
		>
			{hasAnyTitle && (
				<Stack sx={getTitleContainerStyles(contentAlign)}>
					{eyebrow && (
						<Typography
							variant="bodyMediumBook"
							sx={{
								textAlign: resolveContentAlignment(contentAlign, true),
							}}
							color={isDarkBackground ? 'text.light' : 'text.secondary'}
							data-test-id="text_patient_reviews_eyebrow"
						>
							{eyebrow}
						</Typography>
					)}

					{title && (
						<Typography
							component={resolveContentfulHeadingTag(titleHtag)}
							variant="header1"
							sx={{
								textAlign: resolveContentAlignment(contentAlign, true),
							}}
							color={isDarkBackground ? 'text.light' : 'text.primary'}
							data-test-id="text_patient_reviews_title"
						>
							{title}
						</Typography>
					)}

					{subtitle && (
						<Typography
							variant="bodyLargeBook"
							sx={{
								textAlign: resolveContentAlignment(contentAlign, true),
							}}
							color={isDarkBackground ? 'text.light' : 'text.secondary'}
							data-test-id="text_patient_reviews_subtitle"
						>
							{subtitle}
						</Typography>
					)}
				</Stack>
			)}

			{reviews.length > 0 && (
				<Reviews
					isDarkBackground={isDarkBackground}
					reviews={reviews}
					trimThreshold={trimThreshold ?? defaults.trimThreshold}
					useDividers={isSmallScreen}
					vertical={isSmallScreen}
					contentAlign={contentAlign}
					mode={mode}
					scoreIcon={scoreIcon}
				/>
			)}

			{imageLeftSide && !isSmallScreen && (
				<SideImage imageAsset={imageLeftSide} hasButtonContent={hasButtonContent} />
			)}

			{hasButtonContent && (
				<Stack sx={buttonContainerStyles} data-test-id="group_patient_reviews_buttons">
					{buttons.map((button) =>
						button ? (
							<ContentfulButton key={button.sys.id} {...button} dataTestId="button_patient_reviews" />
						) : null
					)}
				</Stack>
			)}
		</TemplateContainer>
	);
}
